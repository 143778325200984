import axios from 'axios'
import store from '@/store'
import {getCookie} from './../lib/utils'
import { Toast } from 'vant';
let server = axios.create({
    responseType: 'json'
});
server.interceptors.request.use(function (config) {
    let token = localStorage.getItem('token')||getCookie('token');
    if (token) {
        config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
}, function (err) {
    return Promise.reject(err);
});

export const  fetch = (url, param = {}) => {
  return new Promise((resolve, reject) => {
    server.post(url, param)
      .then(({data}) => {
          let {status,msg}=data;
          // if(status!=='success'){
          //     store.commit('alertModal', {msg});
          // }
          resolve(data);
      }, err => {
          Toast.fail({
            message: '网络错误',
            duration:'3000'
          });
          // store.commit('alertModal', {msg:'网络错误'});
          reject(err)
      })
  })
}
export const  fetchGet = (url, param ) => {
  return new Promise((resolve, reject) => {
    server.get(url, param)
        .then(({data}) => {
            resolve(data);
        }, err => {
            store.commit('alertModal', {msg:'网络错误'});
            reject(err)
        })
  })
}