import { fetch, fetchGet } from "@/server/server";
//公户-租金计划
export const comconlist = (param) => {
  return fetch('/wxapi/comconlist', param);
};
export const comcardetail = (param) => {
  return fetch('/wxapi/comcardetail', param);
};
export const comconplanpdf = (param) => {
  return fetch('/wxapi/comconplanpdf', param);
};
export const comcarplanpdf = (param) => {
  return fetch('/wxapi/comcarplanpdf', param);
};
export const verifymsg = (param) => {
  return fetch('/wxapi/verifymsg', param);
};
export const getpublicbindstatus = (param) => {
  return fetch('/wxapi/bindstatus', param);
};
export const comCusbinding = (param) =>{
  return fetch('/wxapi/com_cusbinding',param)
}
export const frbinding = (param) =>{
  return fetch('/wxapi/frbinding',param)
}
export const getifvalid = (param1,param2) =>{
  return fetchGet('/wxapi/getifvalid?file_name='+ param1 + '&create_date='+ param2)
}
export const msgcodeSv = (param) =>{
  return fetch('/wxapi/msgcode_sv',param)
}