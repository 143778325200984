<template>
  <div class='bindFlex' :style="{'background': identityType ? '#FFFFFF' : '#F5F5F5' }"> 
    <div class="wrap" > 
      <van-overlay :show="isLoading">
        <van-loading @click.stop size="24px" style="text-align:center;top:50px;margin-top: 3rem;" v-show="isLoading">正在加载...</van-loading>
      </van-overlay>
      <div>
        <div class='lineGrey'></div>
        <div class='step1'>
          <div class='step1_header'>第①步：选择绑定人身份</div>
          <div class='step1_content'>
            <div class='line_left'>绑定人身份</div>
            <div class='line-right'>
              <van-radio-group 
                v-model="identityType" 
                direction="horizontal" 
                checked-color='#E50012'
                @change='typeChange'
              >
                <van-radio name="1">法定代表人</van-radio>
                <van-radio name="2">其他</van-radio>
              </van-radio-group>
            </div>
          </div>
        </div>
        <div class='lineGrey' v-if='identityType'></div>
        <div class='step2'>
          <div v-if='identityType == 1'>
            <div v-if='comdtDicList.length > 0' class='step1' style='padding-bottom: 20px;'>
              <div class='step1_header'>第②步：查看您名下融租企业</div>
              <div class='totalLine'>
                <div class='total_text1'>企业名称</div>
                <div class='total_text2'>共{{comdtDicList.length}}家企业</div>
              </div>
              <div class='enterpriseLine' v-for='(item, index) in comdtDicList' :key='index'>
                <div class='enterprise_text1'>{{item.company_name}}</div>
                <div class='enterprise_text2'>{{item.company_certid}}</div>
              </div>
              <!-- <div class='confirmBind' v-if='identityType '>
                <div class='bindBtn' @click='toBind'>确认绑定</div>
              </div> -->
            </div>
            <div class='noData_block' v-else>
              <img src='@/assets/img/bind_tip.png' class='noData_img' />
              <div class='noData_text'>您名下暂无融租企业或绑定手机号与系统法定代表人预留手机号不一致</div>
            </div>
          </div>
          <div v-if='identityType == 2' >
            <div style='padding-bottom: 65px;'>
              <div class='step1_header'>第②步：填写绑定企业并验证法人信息</div>
              <div v-for='(item, index) in enterpriseList' :key='index' >
                <van-field
                  v-if='index == 0'
                  v-model="item.value"
                  :label="'企业'+(index+1)"
                  :autosize='true'
                  rows='1'
                  type="textarea"
                  maxlength='50'
                  placeholder="请输入企业全称"
                  style='border-bottom: 1px solid #EEEEEE;padding-left: 0;margin-left: 20px;width: auto;'
                >
                  <div slot='right-icon' class='local-icon' @click='addEnterprise'>
                    <img src='@/assets/img/add.png' >
                  </div>
                </van-field>
                <van-field
                  v-else
                  v-model="item.value"
                  :label="'企业'+(index+1)"
                  rows='1'
                  :autosize='true'
                  maxlength='50'
                  type="textarea"
                  placeholder="请输入企业全称"
                  style='border-bottom: 1px solid #EEEEEE;padding-left: 0;margin-left: 20px;width: auto;'
                >
                  <div slot='right-icon' class='local-icon' @click='reduceEnterprise(index)'>
                    <img src='@/assets/img/reduce.png' >
                  </div>
                </van-field>
              </div>
              <van-field
                v-model="bindValue.card_id"
                label="证件号码"
                placeholder="请输入企业法人身份证号"
                @blur='cardIdBlur'
                maxlength='18'
              >  
              </van-field>
              <van-field
                v-model="bindValue.mobile"
                label="手机号码"
                placeholder="请输入企业法人手机号码"
                @blur="mobileBlur"
                type="number"
                maxlength='11'
              >  
              </van-field>
              <van-field
                v-model="bindValue.inputcode"
                name="inputcode"
                maxlength="4"
                center
                clearable
                type="number"
                label="验证码"
                placeholder="请输入验证码"
                @blur='inputcodeBlur'
              >
                <template #button>
                  <van-button v-show="!visible" native-type="button" size="small" type="danger" @click="getSmsCode()">获取验证码</van-button>
                  <van-button v-show="visible" native-type="button" plain size="small" type="danger">{{timeSeconds}}</van-button>
                </template>
              </van-field>
            </div>
            <!-- <div class='confirmBind' v-if='identityType '>
              <div class='bindBtn' @click='toBind'>确认绑定</div>
            </div> -->
          </div>
          
        </div>
      </div>
    </div>
    <div class='confirmBind' v-if='(identityType == 1 && comdtDicList.length > 0) || identityType == 2'>
      <div class='bindBtn' @click='toBind'>确认绑定</div>
    </div>
  </div>
  </template>
  <script>
    import { getMobileCode } from '@/server'
    import { comCusbinding, frbinding } from '@/api/enterprise'
    import { Toast,Dialog,Grid, GridItem,Overlay } from 'vant';
    import { setCookie, getCookie, domainUrl, hidewxOption } from '@/lib/utils';
    import Loading from "@/components/Loading";
    // window.location.reload() 
    export default {
      components: {
        Loading
      },
      name:'enterpriseBind',
      data() {
        return{
          isLoading: false,
          identityType: '',
          enterpriseList: [{

          }],
          visible: false,
          timeSeconds: '',
          canclick: true,
          bindValue: {},
          telNum: this.$route.query.telNum,
          cardId: this.$route.query.cardId,
          comdtDicList: []
        }
      },
      mounted(){

      },
      created(){
        //隐藏分享
				hidewxOption()
        
      },
      methods:{
        typeChange(value){
          if(value == 1){
            this.isLoading = true
            frbinding({
              openid: this.$store.state.openId,
              access_token: this.$store.state.accessToken,
              card_type : "fr",
              card_id: this.cardId,
              mobile: this.telNum
            }).then(res =>{
              this.isLoading = false
              if(res.com_bind_status == '100'){
                this.comdtDicList = res.com_binds
              }
            })
          }
        },
        addEnterprise(){
          this.enterpriseList.push({})
        },
        reduceEnterprise(index){
          this.enterpriseList.splice( index, 1 )
        },
        formatter(value) {
          // 只能输入数字/\D/g,''
          return value.replace(/\D/g, '');
        },
        cardIdBlur(){
          if(!this.bindValue.card_id){
            Toast('请输入企业法人身份证号')
            return false
          }else if(this.bindValue.card_id.length != 18){
            Toast('请输入正确的身份证号')
            return false
          }
          return true
        },
        mobileBlur(){
          let reg = new RegExp(/^1[3456789]\d{9}$/)
          let regBoo = reg.test(this.bindValue.mobile)
          if(!this.bindValue.mobile){
            Toast('请输入企业法人手机号')
            return false
          }else if(!regBoo){
            Toast('请输入正确的手机号')
            return false
          }
          return true
        },
        inputcodeBlur(){
          if(!this.bindValue.inputcode){
            Toast('请输入验证码')
            return false
          }else if(this.bindValue.inputcode.length != 4){
            Toast('请输入正确的验证码')
            return false
          }
          return true
        },
        sendMessage(second) {
          if(second >= 0) {
            this.visible = true;
            this.timeSeconds = second + "s"
            second--;
            setTimeout(() => {
              this.sendMessage(second);
            }, 1000);
          } else {
            this.visible = false;
          }
        },
        //获取验证码
        getSmsCode(){
          if(this.canclick){
            if( this.cardIdBlur() && this.mobileBlur() ){
              const params = {
                card_id: this.bindValue.card_id,
                mobile: this.bindValue.mobile,
                openid: this.$store.state.openId
              }
              this.canclick = false
              getMobileCode(params).then(res =>{
                this.canclick = true
                if(res.cus_status=='0'){
                  this.sendMessage(120);
                }else{
                  Toast.fail({
                    message: res.msg,
                    duration:'3000'
                  });
                }
              })
            }
          }
        },
        toBind(){
          if(this.identityType == 1){
            comCusbinding({
              openid: this.$store.state.openId,
              access_token : this.$store.state.accessToken,
              card_type : "fr",//（fr:法人，other:其他）
              card_id : this.cardId,
              mobile : this.telNum
            }).then(res =>{
              if(res.com_bind_status == '1'){
                // this.$store.commit('setPublicData', res)
                Toast({
                  message: '绑定成功！',
                  icon: require('@/assets/img/bindSuccess.png'),
                  duration:'3000'
                });
                sessionStorage.setItem('keyValue','enterprisePersonalCenter')
                this.$router.push({ path: '/enterprise/enterprisePersonalCenter'})
              }else{
                Toast.fail({
                  message: res.msg,
                  duration:'3000'
                });
              }
            })
          }else if(this.identityType == 2){
            if(this.cardIdBlur() && this.mobileBlur() && this.inputcodeBlur()){
              let enterpriseStr = ''
              this.enterpriseList.map((item)=>{
                if(item.value){
                  enterpriseStr += item.value + ','
                }
              })
              if(enterpriseStr){
                comCusbinding({
                  openid: this.$store.state.openId,
                  access_token : this.$store.state.accessToken,
                  card_type : "other",//（fr:法人，other:其他）
                  card_id : this.bindValue.card_id.toUpperCase(),
                  mobile : this.bindValue.mobile,
                  inputcode: this.bindValue.inputcode,
                  comnames: enterpriseStr.slice(0,-1),
                  loginCard: this.cardId,
                  loginMobile: this.telNum
                }).then(res =>{
                  if(res.com_bind_status == '1'){
                    // this.$store.commit('setPublicData', res)
                    Toast({
                      message: '绑定成功！',
                      icon: require('@/assets/img/bindSuccess.png'),
                      duration:'3000'
                    });
                    sessionStorage.setItem('keyValue','enterprisePersonalCenter')
                    this.$router.push({ path: '/enterprise/enterprisePersonalCenter'})
                  }else{
                    Toast.fail({
                      message: res.msg,
                      duration:'3000'
                    });
                  }
                })
              }else{
                Toast('请输入企业名称')
              }
            }
          }
        }
      }
    }
  </script>
  <style scoped>
  .lineGrey{
    width: 100%;
    height: 10px;
    background-color: #F5F5F5;
  }
  .bindFlex{
    height: 100vh;
    background: rgb(248, 248, 248);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .step1{
    background-color: #FFFFFF;
  }
  .step1_header{
    color: #E50012;
    font-size: 15px;
    font-weight: bold;
    padding: 14px 20px;
  }
  .step1_content{
    height: 52px;
    padding: 0 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
  .line_left{
    color: #333333;
    font-size: 15px;
  }
  .totalLine{
    height: 50px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-left: 20px;
    padding-right: 20px;
    border-bottom: 1px solid #EEEEEE;
  }
  .total_text1{
    color: #333333;
    font-size: 15px;
  }
  .total_text2{
    color: #666666;
    font-size: 15px;
  }
  .enterpriseLine{
    margin-left: 20px;
    border-bottom: 1px solid #EEEEEE;
    padding: 10px 20px 10px 0;
  }
  .enterprise_text1{
    color: #333333;
    font-size: 15px;
    line-height: 20px;
  }
  .enterprise_text2{
    color: #666666;
    font-size: 15px;
    margin-top: 5px;
    line-height: 20px;
  }
  .confirmBind{
    background-color: #FFFFFF;
    /* position: fixed;*/
    /* bottom: 0px;
    left: 0px; 
    position:absolute; */
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 65px;
    
  }
  .bindBtn{
    margin-top: 20px;
    margin-bottom: 10px;
    width: 335px;
    height: 45px;
    line-height: 45px;
    border-radius: 45px;
    text-align: center;
    color: #FFFFFF;
    background-color: #E50012;
  }
  .step2{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: #FFFFFF;
  }
  .local-icon img{
    width: 17px;
    height: 17px;
  }
  .noData_block{
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    background-color: #FDEEDF;
    padding : 12px 0;
  }
  .noData_img{
    width: 12px;
    height: 12px;
    margin-left: 10px;
    margin-right: 3px;
    margin-top: 4px;
  }
  .noData_text{
    color: #FF5100;
    font-size: 15px;
    line-height: 20px;
    margin-right: 10px;
  }
  </style>